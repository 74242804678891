import { Component, Input, OnInit } from '@angular/core';
import {
  _getPrintLabels,
  Finishing,
  OrderPaymentStatus,
  OrderProcessStatus,
  PageOrientation,
  PageSize,
  PageTurn,
  PaperWeight,
  PrintingGroup,
  Settings
} from 'src/app/models/models';
import { ProductType } from 'src/app/models/product.model';
import { getPrintFilesColorException, getPrintGroupActualColor, PrintFileColorExceptionLabel, PrintGroupActualColorLabel } from 'src/app/shared/utils/print-group-document-helper';

@Component({
  selector: 'app-print-group-info',
  templateUrl: './print-group-info.component.html',
  styleUrls: ['./print-group-info.component.scss']
})
export class PrintGroupInfoComponent implements OnInit {
  private _item: any;
  public get item(): any {
    return this._item;
  }

  @Input()
  public set item(v: any) {
    this._item = v;
    if (v) this.printLabels = getAdminPrintLabels(v.product.printingGroup);
  }

  orderProcessStatus = OrderProcessStatus; //= OrderProcessStatus;
  orderPaymentStatus = OrderPaymentStatus;
  productType = ProductType;
  finishing = Finishing;

  printLabels: Array<any> = [];

  constructor() {
  }

  ngOnInit(): void {
    this.printLabels = getAdminPrintLabels(this.item.product.printingGroup);
  }
}

export function getAdminPrintLabels(printingGroup: PrintingGroup, short: boolean = false) {
  const { files, printSettings: settings } = printingGroup;
  const printGroupActualColor = getPrintGroupActualColor(files, settings);
  const printFilesColorException = getPrintFilesColorException(files, settings, printGroupActualColor);

  // return: [{ label, classes, tooltip? }]
  let labels = [];
  let preset = [];
  let _settings;
  let label;
  let classes;
  let tooltip;

  // build preset label (WXYZ); if autoRotate: WXYZ / WX~YZ (with the complement)
  label = 'Preset: ';
  preset.push(settings[Settings.TWO_SIDED] ? '2' : '1'); // 1, 2
  preset.push(settings[Settings.PAGES_PER_SHEET]); // 1, 2, 4
  preset.push(settings[Settings.PAGE_ORIENTATION] == PageOrientation.LANDSCAPE ? 'H' : 'V'); // horizontal, vertical
  preset.push(settings[Settings.PAGE_TURN] == PageTurn.SHORTSIDE ? 'C' : 'L'); // corto, largo
  label += preset.join('');
  if (settings[Settings.AUTO_ROTATE]) { // repeat but swap page orientation
    label += '/';
    preset[2] = preset[2] == 'H' ? 'V' : 'H'; // page orientation complement on auto-rotation
    label += preset.join('');
  }
  classes = labelClasses(settings[Settings.AUTO_ROTATE] === false); // undefined and true are not hl
  // classes = this.labelClasses(false);
  // and tooltip
  _settings = {};
  [Settings.TWO_SIDED, Settings.PAGES_PER_SHEET,
  Settings.PAGE_ORIENTATION, Settings.PAGE_TURN, Settings.AUTO_ROTATE]
    .forEach(e => {
      _settings[e] = settings[e];
    });
  tooltip = _getPrintLabels(_settings).join(', ');
  // labels.push({ p: 'pepe 2'});
  labels.push({ label: label, classes: classes, tooltip: tooltip });

  // build labels for rest of settings. one by one so we can create class...

  // page size
  _settings = {};
  _settings[Settings.PAGE_SIZE] = settings[Settings.PAGE_SIZE];
  label = _getPrintLabels(_settings);
  classes = labelClasses(
    settings[Settings.PAGE_SIZE] !== PageSize.A4, // default
    settings[Settings.PAGE_SIZE] == PageSize.A5, // A5 highlight alternative colour
  );
  labels.push({ label: label, classes: classes });

  // paper weight
  _settings = {};
  _settings[Settings.PAPER_WEIGHT] = settings[Settings.PAPER_WEIGHT];
  label = _getPrintLabels(_settings);
  classes = labelClasses(settings[Settings.PAPER_WEIGHT] !== PaperWeight.W80); // default
  labels.push({ label: label, classes: classes });

  // color (new: with actual group color + exception)
  _settings = {};
  label = PrintGroupActualColorLabel[printGroupActualColor];
  if (printFilesColorException) {
    label += ` (${PrintFileColorExceptionLabel[printFilesColorException]})`;
  }
  classes = labelClasses(false); // no highlighting
  labels.push({ label: label, classes: classes });
  // // old color:
  // _settings = {};
  // _settings[Settings.COLOR] = settings[Settings.COLOR];
  // _settings[Settings.COVER_COLOR] = settings[Settings.COVER_COLOR];
  // _settings[Settings.COVER_LAMINATED] = settings[Settings.COVER_LAMINATED];
  // _settings[Settings.DOC_COLOR] = settings[Settings.DOC_COLOR];
  // label = _getPrintLabels(_settings);
  // classes = labelClasses(false); // no highlighting
  // labels.push({ label: label, classes: classes });

  // finishing
  _settings = {};
  _settings[Settings.FINISHING] = settings[Settings.FINISHING];
  _settings[Settings.GROUPED] = settings[Settings.GROUPED];
  if (!short) {
    if (settings[Settings.RING_COLOR] && settings[Settings.RING_COLOR].id !== 'transparent') { // TODO: this default should be defined somewhere
      _settings[Settings.RING_COLOR] = settings[Settings.RING_COLOR];
    }
    if (settings[Settings.HARD_COVER_FRONT] && settings[Settings.HARD_COVER_FRONT].id !== 'transparent') { // TODO: this default should be defined somewhere
      _settings[Settings.HARD_COVER_FRONT] = settings[Settings.HARD_COVER_FRONT];
    }
    if (settings[Settings.HARD_COVER_BACK] && settings[Settings.HARD_COVER_BACK].id !== 'black') { // TODO: this default should be defined somewhere
      _settings[Settings.HARD_COVER_BACK] = settings[Settings.HARD_COVER_BACK];
    }
  }
  label = _getPrintLabels(_settings);
  classes = labelClasses(false); // no highlighting
  labels.push({ label: label, classes: classes });

  return labels;
}

const labelClasses = (hl: boolean, alt: boolean = false) => {
  let classes = ['print-setting-label'];
  if (hl) {
    classes.push(alt ? 'print-setting-label-hl-alt' : 'print-setting-label-hl');
  }
  return classes.join(' ');
  // return { 'print-setting-label': true, 'print-setting-label-hl': hl };
}
