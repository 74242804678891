import { Fees } from 'projects/shared-lib/src/lib/models/fee.model';
import { PostalAddress } from '../models/models';
import { ImpresionContentFilterType } from '../admin/produccion/impresion/impresion.service';

export class UserModel {
  id: string;
  ambassadorProducts?: string[];
  displayName: string; //
  firstName: string; //
  lastName: string; //
  email: string;
  emailVerified: boolean;
  phoneNumber?: string;
  phoneNumberVerified: false;
  address?: string; //
  location: Location;
  locationUpdated: Date;
  photoURL: string;
  uid: string;
  regStatus: UserModel.RegStatus;
  provider: {
    // facebook.com:1278172
    // password:email
  };
  createdAt: Date;
  updatedAt: Date;
  monthlyPayment: boolean; // activación de remesa: true. sin remesa: false.

  shippingAddress?: PostalAddress;
  billingAddress?: PostalAddress;

  policy?: boolean;
  ads?: boolean;

  // admin:
  hidden?: boolean;
  role?: string;
  disabled?: boolean;
  // showDashboard?:boolean

  //new
  fees: Fees;

  acceptance: {
    privacy: boolean;
    tos: boolean;
    referrer: boolean;
    ads: boolean;
  }

  isCreator?: boolean; //New v4
  featured?: boolean; //new v4
  description?: string; //new v4
  slug?: string; //new v4  
  limitedFees: boolean;

  impresionContentFilterTypes?: Array<ImpresionContentFilterType>;

}

// user reference (regular and admin)
export class _User { // NEW 2
  id: string;  // user id (reference to users collection)
  displayName: string;
}

export class MetaReferrer { // sub-collection 'meta'
  accpetanceId: string;
  userId: string;
  name: string;
  taxId: string;
  address: PostalAddress;
  bankAccount?: string;
  autoAssignRecurrentCoupon?: boolean;
  recurrentCoupon?: string; //XA: 10/oct/2022  https://app.clickup.com/t/3jv3eet
}

export class Token { // sub-collection
  createdAt: Date;
  updatedAt: Date;
  device: any; // as reported by device
  lang: any; // as reported by device
  token: string; // push token
}


export namespace UserModel {
  export class Reference {
    id: string;  // user id (reference to users collection)
    displayName: string;

    constructor(user: UserModel) {
      if (user) {
        const { id, displayName } = user;
        return { id, displayName };
      }
    }
  }


  export enum RegStatus {
    Completed,
    Location,
    Name
  }

}
